import React from 'react';
import PropTypes from 'prop-types';

import { getCurrentPageProps, getPath } from '../../../utils';

import '../../../styles/index.scss';

import HOC from '../organisms/root';
import Grid, { GridRow, GridCol } from '../atoms/grid';
import { HeroContainer, ContentContainer, ContentWrapper, Tile } from '../molecules/container';
import MainNavigation from '../organisms/mainNavigation';
import { Devider, Spacer } from '../atoms/devider';
import { SpotlightContent } from '../molecules/spotlight';
import Header from '../molecules/header';
import Footer from '../molecules/footer';
import { H1, H3 } from '../atoms/header';
import Address from '../atoms/address';
import { DefinitionList } from '../atoms/definitionList';

const Layout = ({ children, data }) => {
  return data ? (
    <HOC>
      {children}
      <MainNavigation navLinks={data.mainNavLinks} pageType="main" />
      <Header>
        <HeroContainer
          contentHeader={getCurrentPageProps(data.heroHeaders, getPath())}
          contentFeatured={getCurrentPageProps(data.heroFeatured, getPath())} />
      </Header>
      <Spacer spacing={2} />
      <Grid gap={20} noPadding={true} offset={true}>
        <GridRow direction="row">
          <GridCol colSize={5} stretched={true}>
            <SpotlightContent content={getCurrentPageProps(data.spotlightContent, getPath())} />
          </GridCol>
          <GridCol colSize={7}>
            <Grid noPadding={true}>
              <GridRow direction="column">
                <GridCol>
                  <ContentContainer content={getCurrentPageProps(data.contentContainer, getPath(), "strategy")} />
                </GridCol>
                <Devider />
                <GridCol>
                  <ContentContainer content={getCurrentPageProps(data.contentContainer, getPath(), "technology")} />
                </GridCol>
              </GridRow>
            </Grid>
          </GridCol>
        </GridRow>
        <Devider margin={10} />
        <GridRow direction="row">
          <GridCol colSize={5}>
            <ContentContainer content={getCurrentPageProps(data.contentContainer, getPath(), "iot")} />
          </GridCol>
          <GridCol colSize={7}>
            <ContentContainer content={getCurrentPageProps(data.contentContainer, getPath(), "mobileApps")} />
          </GridCol>
        </GridRow>
      </Grid>
      {
        data.casesContent &&
        (
          <Grid gap={10} noPadding={true} offset={true}>
            <Devider margin={10} />
            <H1 type="section">{data.casesContent.title}</H1>
            <GridRow direction="row">
              {
                [...data.casesContent.items.slice(0, 3)].map((caseItem, index) => (
                  <GridCol colSize={4} key={index}>
                    <ContentContainer content={caseItem} />
                  </GridCol>
                ))
              }
            </GridRow>
          </Grid>
        )
      }
      <Grid gap={10} noPadding={true} offset={true}>
        <Devider margin={10} />
        <GridRow direction="row">
          {
            [...data.casesContent.items.slice(3, 5)].map((caseItem, index) => (
              <GridCol colSize={4} key={`case-col-${index}`}>
                <ContentContainer content={caseItem} />
              </GridCol>
            ))
          }
          <GridCol colSize={4} isFlex={false}>
            <ContentContainer isButtonBlock={true} content={getCurrentPageProps(data.buttonBlocks, '*', "discoverMore")} />
            <ContentContainer isButtonBlock={true} content={getCurrentPageProps(data.buttonBlocks, '*', "contact")} />
          </GridCol>
        </GridRow>
      </Grid>
      <Grid gap={10} noPadding={true} offset={true}>
        <GridRow direction="row">
          <GridCol colSize={8} colOffsetSize={9}>
            <Grid gap={0} noPadding={true}>
              <GridRow>
                <Devider margin={10} top={false} />
              </GridRow>
              <GridRow>
                <H1 type="section">{data.references.title}</H1>
              </GridRow>
              <GridRow direction="row" isWrapped={true} isGrow={false} fixedColSize={4}>
                {
                  data.references.items.map((caseItem, index) => (
                    <GridCol key={`case-col-${index}`} isWrapped={true} colSpacing={4}>
                      <Tile content={caseItem} />
                    </GridCol>
                  ))
                }
              </GridRow>
            </Grid>
          </GridCol>
          <GridCol colSize={4} colOffsetSize={12} isFlex={false}>
            <ContentWrapper>
              <Grid noPadding={true} offset={false}>
                <GridRow direction="row">
                  <GridCol>
                    <ContentWrapper theme="dark">
                      <H3>Contact Info</H3>
                      <Address content={getCurrentPageProps(data.contactInfo, '*')} className="address" />
                      <Spacer spacing={6} />
                      <DefinitionList prefixed={true} content={getCurrentPageProps(data.contactInfo, '*')} />
                    </ContentWrapper>
                  </GridCol>
                </GridRow>
              </Grid>
            </ContentWrapper>
          </GridCol>
        </GridRow>
      </Grid>
      <Grid gap={10} noPadding={true} offset={true}>
        <Devider margin={10} />
        <GridRow direction="row">
          <GridCol colSize={10}>
            <Footer title={data.footerTitle} navLinks={{items: data.footerNavLinks.items}} />
          </GridCol>
          <GridCol colSize={2} align="right">
            <Footer navLinks={{ctas: data.footerNavLinks.ctas}} />
          </GridCol>
        </GridRow>
      </Grid>
      <Spacer spacing={20} />
    </HOC>
  ) : (
      <>
        No Data
    </>
    );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
