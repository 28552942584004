import React, { Fragment } from 'react';

import { H3 } from '../atoms/header';
import Button from '../atoms/button';
import { Spacer } from './devider';

export const DefinitionList = ({ prefixed=false, content, ...props }) => {
  const prefixedClass = prefixed ? ' definition-list--prefixed' : '';
  return (
    <div className="definition-list__wrapper">
      {
        content.title && !!content.title && <H3>{content.title}</H3>
      }
      {
        content.items && content.items.length > 0 && (
          <dl className={`definition-list ${prefixedClass}`}>
            {
              content.items.map((listItem, index) => (
                <Fragment key={`definition-list-${index}-${+new Date()}`}>
                  <dt title={listItem.label}>{prefixed ? listItem.initials : listItem.label }</dt>
                  <dd>{listItem.info}</dd>
                </Fragment>
              ))
            }
          </dl>
        )
      }
      <Spacer spacing={8} />
      {
        content.ctas.map((item, index) => (
          <Button isBlock={true} key={`definition-list-cta-${index}`} to={item.href} target={item.target} icon={item.icon ? item.icon : ''} text={item.label} />
        ))
      }
    </div>
  );
};
